/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';

import {
  selectBuyOutState,
  getBuyOut,
  selectBuyOutsColumnsState,
  BUYOUT_LIMIT,
} from '@store';
import { getUrl, showError } from '@utils';
import { boardsServices } from '@services';
import { BOARDS, BOARDS_ID } from '@constants';
import { getBuyOutRequestPayload } from 'helpers/buyOut';
import { URLS } from 'layouts/DrawerLayout/constants';

export const useBuyOutOperations = (fetchOnMount) => {
  const { total, loading, buyOut, hasErrors, errorMessage } =
    useSelector(selectBuyOutState);
  const columns = useSelector(selectBuyOutsColumnsState);

  const {
    isReady,
    push,
    query: {
      page = 1,
      pageSize = BUYOUT_LIMIT,
      orderBy = 'stage',
      order = 'asc',
      token,
      ...others
    },
  } = useRouter();
  const dispatch = useDispatch();

  const _otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const _getBuyOut = useCallback(async () => {
    const payload = getBuyOutRequestPayload({
      boardId: BOARDS_ID.BUY_OUT,
      ...others,
    });

    await dispatch(
      getBuyOut({
        orderBy,
        order,
        page,
        pageSize,
        boardId: BOARDS_ID.BUY_OUT,
        boardType: BOARDS.BUY_OUT,
        ...payload,
      })
    );
  }, [page, orderBy, order, _otherQueries, dispatch]);

  const _addTask = useCallback(
    async (payload = {}) => {
      await boardsServices.addTask(
        payload?.parent ? payload?.parent?.split('-')[0] : buyOut?.boardId,
        { ...payload }
      );
      await _getBuyOut();
    },
    [buyOut]
  );

  const _updateTask = useCallback(
    async (payload = {}) => {
      try {
        if (payload?.cardId) {
          const taskId = payload?.cardId;
          delete payload?.cardId;
          await boardsServices.updateTask(taskId, payload);
        } else {
          await boardsServices.updateTask(payload.id, omit(payload, 'id'));
        }
      } catch (error) {
        showError(error);
      }
    },
    [buyOut]
  );

  const _sortBy = useCallback(
    (sortingModel) => {
      const params = {
        ...sortingModel,
        page,
        ...others,
      };

      const url = getUrl(params, URLS.buyout);

      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      const params = {
        orderBy,
        order,
        page: currentPage,
        ...others,
      };
      const url = getUrl(params, URLS.buyout);

      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getBuyOut();
      }
    }, 100);
  }, [page, token, _otherQueries, _getBuyOut, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    loading,
    buyOut,
    columns,
    hasErrors,
    errorMessage,
    addTask: _addTask,
    updateTask: _updateTask,
    getBuyOut: _getBuyOut,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
