import { useEffect, useState } from 'react';

export const useColumnVisibility = (
  apiRef,
  initialGridColumns = [],
  loading = undefined
) => {
  const [gridColumns, setGridColumns] = useState(initialGridColumns);

  useEffect(() => {
    if (loading === false) {
      setGridColumns(initialGridColumns);
    }
  }, [loading]);

  const handleChangeVisibility = (column) => {
    // reset columns functionality
    if (!column) {
      setGridColumns(initialGridColumns);
      initialGridColumns.forEach((col) => {
        apiRef.current.setColumnVisibility(col.key, col.value);
      });
      return;
    }

    const visibleColumns = apiRef.current?.getVisibleColumns?.() || [];
    const isSelectedColumnVisible = visibleColumns.find(
      (col) => col.field === column.key
    );

    setGridColumns(
      gridColumns.map((col) => ({
        ...col,
        value: col.key === column.key ? !col.value : col.value,
      }))
    );
    apiRef.current.setColumnVisibility([column.key], !isSelectedColumnVisible);
  };

  return { handleChangeVisibility, gridColumns, setGridColumns };
};

export default useColumnVisibility;
