import { URLS } from 'layouts/DrawerLayout/constants';

const { request } = require('@utils');

const getExportDataJobs = async () => {
  const results = await request.get(`/v1${URLS.jobs}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { getExportDataJobs };
