/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';

import {
  selectArnmMaintenanceState,
  resetModalArnmMaintenanceChange,
  filtersModalConditionArnmMaintenanceChange,
  filtersModalRulesArnmMaintenanceChange,
} from '@store';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import { BOARDS, BOARDS_ID, SERVICE_POINTS_PAGE_SIZE } from '@constants';
import { URLS } from 'layouts/DrawerLayout/constants';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import {
  agentsServices,
  boardsServices,
  servicePointsServices,
} from '@services';
import {
  getArnmMaintenanceColumns,
  getArnmMaintenancePayload,
  handleArnmMaintenanceData,
  handleAssigneeData,
  handleServicePoints,
} from './utils';

export const ARNM_MAINTENANCE_PAGE_SIZE = 1000;

export const useArnmMaintenanceOperations = () => {
  const dispatch = useDispatch();

  const { filters } = useSelector(selectArnmMaintenanceState);

  const router = useRouter();
  const page = router.query?.page || '1';
  const orderBy = router.query?.orderBy || 'createdAt';
  const order = router.query?.order || 'desc';
  const cleanedParams = getCleanedUrlParams({
    ...omit(router.query, ['view']),
    page,
    orderBy,
    order,
  });

  const assigneesResponse = useQuery(
    QUERY_KEYS.GET_AGENTS_KEY,
    () => {
      return agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
    },
    { enabled: router.isReady }
  );
  const servicePointsResponse = useQuery(
    QUERY_KEYS.GET_SERVICE_POINTS_KEY,
    () => {
      return servicePointsServices.getServicePoints({
        page: 1,
        pageSize: SERVICE_POINTS_PAGE_SIZE,
      });
    },
    { enabled: router.isReady }
  );

  const maintenanceResponse = useQuery(
    [
      QUERY_KEYS.GET_ARNM_MAINTENANCE_TASKS_KEY,
      { cleanedParams, filters: filters.modalFiltersRules },
    ],
    () => {
      const urlParams = getCleanedUrlParams({
        page,
        orderBy,
        order,
        pageSize: ARNM_MAINTENANCE_PAGE_SIZE,
        boardType: BOARDS.ARM_MAINTENANCE,
      });
      const payload = getArnmMaintenancePayload(
        cleanedParams,
        filters.modalFiltersRules
      );

      return boardsServices.getArnmBoardsFilters({
        boardId: BOARDS_ID.ARM_MAINTENANCE,
        urlParams,
        payload,
      });
    },
    { enabled: router.isReady && assigneesResponse?.isFetched }
  );
  useEffect(() => {
    if (maintenanceResponse?.error) {
      showError(maintenanceResponse?.error);
    }
  }, [maintenanceResponse?.error]);

  useEffect(() => {
    if (assigneesResponse?.error) {
      showError(assigneesResponse?.error);
    }
  }, [assigneesResponse?.error]);

  useEffect(() => {
    if (servicePointsResponse?.error) {
      showError(servicePointsResponse?.error);
    }
  }, [servicePointsResponse?.error]);

  const resetModalChange = useCallback(() => {
    dispatch(resetModalArnmMaintenanceChange());
  }, [dispatch]);

  const handleFiltersModalChange = useCallback(
    (conditions) => {
      dispatch(filtersModalConditionArnmMaintenanceChange(conditions));
    },
    [dispatch]
  );

  const handleChangeRules = (rules) => {
    dispatch(filtersModalRulesArnmMaintenanceChange(rules));
  };

  const handlePageChange = (newPage) => {
    router.push(
      getUrl({ ...router.query, page: newPage }, URLS.arnmMaintenance)
    );
  };

  const handleSortBy = (sortByModel) => {
    const url = getUrl(
      { ...router.query, ...sortByModel },
      URLS.arnmMaintenance
    );

    router.push(url);
  };

  const assignees = handleAssigneeData(assigneesResponse?.data?.data || []);
  const arnmMaintenance = handleArnmMaintenanceData(
    maintenanceResponse?.data?.data || [],
    assigneesResponse?.data?.data || []
  );
  const servicePoints = handleServicePoints(
    servicePointsResponse?.data?.data || []
  );
  const columns = getArnmMaintenanceColumns(arnmMaintenance);

  return {
    page,
    total: maintenanceResponse?.data?.meta?.total,
    pageSize: ARNM_MAINTENANCE_PAGE_SIZE,
    loading:
      maintenanceResponse?.isLoading ||
      assigneesResponse?.isLoading ||
      servicePointsResponse?.isLoading,
    arnmMaintenance,
    servicePoints,
    columns,
    assignees,
    filters,
    handleFiltersModalChange,
    handleChangeRules,
    resetModalChange,
    getArnmMaintenance: maintenanceResponse.refetch,
    nextPage: handlePageChange,
    sortBy: handleSortBy,
  };
};
