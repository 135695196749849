import { agentsServices } from '@services';
import { getUrl, showError } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { handleExportDataJobsData } from './utils';

const { QUERY_KEYS } = require('constants/queryKeys');
const { useQuery } = require('react-query');
const { exportDataServices } = require('services/exportData');

export const useExportData = () => {
  const router = useRouter();
  const page = router.query?.page || '1';

  const exportDataJobsResponse = useQuery(
    [QUERY_KEYS.GET_EXPORT_DATA_KEY],
    () => exportDataServices.getExportDataJobs()
  );

  const assigneesResponse = useQuery(
    QUERY_KEYS.GET_BILLING_ASSIGNEES_KEY,
    () =>
      agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      }),
    { enabled: exportDataJobsResponse?.isFetched }
  );

  const exportDataJobs = handleExportDataJobsData(
    exportDataJobsResponse?.data?.data || [],
    assigneesResponse?.data?.data || []
  );

  const handlePageChange = (_event, newPage) => {
    router.push(
      getUrl({ ...router.query, page: newPage || '1' }),
      URLS.exportData
    );
  };

  const handleSortBy = (sortParam) => {
    const url = getUrl({ ...router.query, ...sortParam }, URLS.exportData);
    router.push(url);
  };

  useEffect(() => {
    if (exportDataJobsResponse?.isError) {
      showError(exportDataJobsResponse?.error);
    }
  }, [exportDataJobsResponse?.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assigneesResponse?.isError) {
      showError(assigneesResponse?.error);
    }
  }, [assigneesResponse?.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    exportDataJobs,
    total: exportDataJobsResponse?.data?.meta?.total,
    loading:
      exportDataJobsResponse?.isFetching || assigneesResponse?.isFetching,
    page,
    refresh: exportDataJobsResponse?.refetch,
    handlePageChange,
    handleSortBy,
  };
};
