import { formatDate, removeHtmlTags } from '@utils';
import moment from 'moment';

export const handleMailBoxes = (data) => {
  if (!data?.length) return [];
  return data?.map((item) => {
    return {
      value: item?.id,
      text: item?.address,
    };
  });
};

export const handleEmailListViewDate = (date) => {
  if (!date) {
    return '-';
  }
  const currentDate = moment();
  const messageDate = moment(date);
  if (currentDate.year() !== messageDate.year()) {
    return formatDate(date, 'DD/MM/YYYY');
  }
  if (currentDate.isSame(messageDate, 'day')) {
    return formatDate(date, 'HH:mm');
  }
  return formatDate(date, 'MMM DD');
};

export const handleCommunicationData = (data) => {
  if (!data.length > 0) {
    return [];
  }
  const emails = [];
  data?.forEach((item) => {
    const newEmail = {
      ...item,
      createdAt: item?.createdAt ? formatDate(item?.createdAt, 'MMMM DD') : '',
      updatedAt: item?.updatedAt ? formatDate(item?.updatedAt, 'MMMM DD') : '',
      messageDate: handleEmailListViewDate(item?.messageDate),
      assignee: item?.assignee,
      snippet: removeHtmlTags(item?.snippet),
    };
    emails.push(newEmail);
  });
  return emails;
};

export const handleMarkAsReadUnread = (data, emailsId, isRead) => {
  if (!data.length) {
    return [];
  }
  data = data?.map((item) => {
    if (emailsId?.includes(item?.id)) {
      return {
        ...item,
        read: isRead,
      };
    }
    return item;
  });
  return data;
};

export const handleMoveToTeam = (data, emailsId, team) => {
  if (!data.length) {
    return [];
  }
  data = data?.map((item) => {
    if (emailsId?.includes(item?.id)) {
      return {
        ...item,
        team,
      };
    }
    return item;
  });
  return data;
};

export const handleSetStatus = (data, emailsId, status) => {
  if (!data.length) {
    return [];
  }
  data = data?.map((item) => {
    if (emailsId?.includes(item?.id)) {
      return {
        ...item,
        status,
      };
    }
    return item;
  });
  return data;
};

export const handleAssignPerson = (data, emailsId, person) => {
  if (!data.length) {
    return [];
  }
  data = data?.map((item) => {
    if (emailsId?.includes(item?.id)) {
      return {
        ...item,
        assignee: person?.id
          ? {
              id: person?.id,
              firstName: person?.firstName,
              lastName: person?.lastName,
              email: person?.email,
            }
          : null,
      };
    }
    return item;
  });
  return data;
};

export const _handleUserData = (to) => {
  let toString = '';
  to?.forEach((item) => {
    if (item?.linkedUserFirstName) {
      toString = `${toString} ${item?.linkedUserFirstName}`;
    }
    if (item?.linkedUserLastName) {
      toString = `${toString} ${item?.linkedUserLastName}`;
    }
    if (item?.emailAddress) {
      toString = `${toString} <${item?.emailAddress}>`;
    }
  });
  return toString;
};
