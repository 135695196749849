import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';
import { pickBy, omit } from 'lodash';

export const computeEmailListPayload = (queryParams = {}) => {
  let payload = localStorage.communicationQuery; // This storage contains the values of filter modals
  let unifiedViewFilterRules = [];
  const fields =
    omit(queryParams, ['page', 'pageSize', 'order', 'orderBy']) || {}; // This storage contains the values of the outer filters

  const numberFields = [];
  const multyFilterFields = ['team', 'assignee', 'status'];
  const fieldNames = {
    team: 'team',
    status: 'status',
    assignee: 'assignee.email',
    side: 'side',
    mailboxId: 'mailboxId',
    isTop: 'isTop',
    searchTerm: 'searchTerm',
  };

  const _getValue = ({ field }) => {
    const fieldValue = queryParams[field];
    if (multyFilterFields.includes(field)) {
      return fieldValue?.split(',');
    }

    return numberFields.includes(field) ? Number(fieldValue) : fieldValue;
  };

  const _getType = ({ field }) => {
    if (multyFilterFields.includes(field)) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const _getField = (field) => {
    if (field === fieldNames.searchTerm) {
      return 'from.emailAddress';
    }
    return fieldNames[field];
  };
  delete fields.side;
  const newFields = pickBy(fields);
  const keys = Object?.keys?.(newFields);

  if (keys?.length) {
    unifiedViewFilterRules = [
      ...keys.map((field) => {
        return {
          field: _getField(field),
          operator: FILTER_OPERATOR_ENUMS['='],
          type: _getType({ field }),
          value: _getValue({ field }),
        };
      }),
    ];
  }

  // special rule for getting unassigned mails
  if (queryParams.assignee === '') {
    unifiedViewFilterRules.push({
      field: fieldNames.assignee,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: FILTER_RULE_ENUMS.isEmpty,
    });
  }

  if (payload && payload !== 'undefined') {
    payload = JSON.parse(payload);
    if (payload.value) {
      payload.value = [...payload.value, ...unifiedViewFilterRules];
    } else if (unifiedViewFilterRules?.length) {
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: unifiedViewFilterRules,
      };
    }
  } else if (!payload && unifiedViewFilterRules?.length) {
    payload = {
      type: 0,
      field: null,
      operator: null,
      value: unifiedViewFilterRules,
    };
  } else {
    payload = {
      type: FILTER_RULE_ENUMS.EmptyRuleType,
    };
  }
  return payload;
};
