export const getColumnsVisibility = (state, columns) => {
  return columns.reduce((acc, cur) => {
    if (state) {
      acc.push({
        ...cur,
        // eslint-disable-next-line no-unneeded-ternary
        value: state[cur.key] === false ? false : true,
      });
    } else {
      acc.push({
        ...cur,
        value: true,
      });
    }

    return acc;
  }, []);
};

export default getColumnsVisibility;
