import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Initial state
const initialState = {
  fleetDataGrid: {
    all: {},
    created: {},
    order: {},
    customs: {},
    payment: {},
    dealer: {},
    procurement: {},
    fleet: {},
    cancelled: {},
    defleeted: {},
  },
  availabilityGrid: {},
  reservationsGrid: {},
  subscriptionsGrid: {},
  arnmRepairsGrid: {},
  arnmMaintenanceGrid: {},
};
// Actual Slice
export const uiSettingsSlice = createSlice({
  name: 'uiSettings',
  initialState,
  reducers: {
    saveFleetDataGrid: (state, { payload }) => {
      state.fleetDataGrid[payload.stage] = payload.gridState;
    },
    saveAvailabilityGrid: (state, { payload }) => {
      state.availabilityGrid = payload.gridState;
    },
    saveReservationsGrid: (state, { payload }) => {
      state.reservationsGrid = payload.gridState;
    },
    saveSubscriptionsGrid: (state, { payload }) => {
      state.subscriptionsGrid = payload.gridState;
    },
    saveArnmRepairsGrid: (state, { payload }) => {
      state.arnmRepairsGrid = payload.gridState;
    },
    saveArnmMaintenanceGrid: (state, { payload }) => {
      state.arnmMaintenanceGrid = payload.gridState;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  saveFleetDataGrid,
  saveAvailabilityGrid,
  saveReservationsGrid,
  saveSubscriptionsGrid,
  saveArnmRepairsGrid,
  saveArnmMaintenanceGrid,
} = uiSettingsSlice.actions;

export const saveFleetDataGridState = ({ gridState, stage }) => {
  return async (dispatch) => {
    dispatch(saveFleetDataGrid({ gridState, stage }));
  };
};

export const saveAvailabilityGridState = ({ gridState }) => {
  return async (dispatch) => {
    dispatch(saveAvailabilityGrid({ gridState }));
  };
};

export const saveReservationsGridState = ({ gridState }) => {
  return async (dispatch) => {
    dispatch(saveReservationsGrid({ gridState }));
  };
};

export const saveSubscriptionsGridState = ({ gridState }) => {
  return async (dispatch) => {
    dispatch(saveSubscriptionsGrid({ gridState }));
  };
};

export const saveArnmRepairsGridState = ({ gridState }) => {
  return async (dispatch) => {
    dispatch(saveArnmRepairsGrid({ gridState }));
  };
};

export const saveArnmMaintenanceGridState = ({ gridState }) => {
  return async (dispatch) => {
    dispatch(saveArnmMaintenanceGrid({ gridState }));
  };
};

// selectors
export const selectUiSettingsState = (state) => state.uiSettings;
export const selectFleetDataGridState = (state) =>
  state.uiSettings.fleetDataGrid;
export const selectAvailabilityDataGridState = (state) =>
  state.uiSettings.availabilityGrid;
export const selectReservationsGridDataGridState = (state) =>
  state.uiSettings.reservationsGrid;
export const selectSubscriptionsDataGridState = (state) =>
  state.uiSettings.subscriptionsGrid;
export const selectArnmRepairsGridDataState = (state) =>
  state.uiSettings.arnmRepairsGrid;
export const selectArnmMaintenanceGridState = (state) =>
  state.uiSettings.arnmMaintenanceGrid;

export default uiSettingsSlice.reducer;
