const findSlugColor = (colors, color) => {
  let slug = '';
  for (let i = 0; colors?.length >= i; i++) {
    if (colors[i]?.name === color) {
      slug = colors[i]?.slug;

      break;
    }
  }
  return slug;
};

export default findSlugColor;
