import { useState } from 'react';
import { useRouter } from 'next/router';

export const useArchivedTasks = (initialState = false) => {
  const router = useRouter();
  const [showArchived, setShowArchived] = useState(initialState);

  const toggleArchivedTasks = () => {
    if (showArchived) {
      delete router.query.archived;
      router.query.page = 1;
      router.push(router);
      setShowArchived(false);
    } else {
      router.query.page = 1;
      router.query.archived = true;
      router.push(router);
      setShowArchived(true);
    }
  };

  return { showArchived, toggleArchivedTasks };
};

export default useArchivedTasks;
