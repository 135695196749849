import { request } from '@utils';
import { LIMIT } from '@constants';

const getUsers = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const results = await request.get(`/v1/users`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUsersEnums = async () => {
  const results = await request.get(`/v1/users/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUser = async (id) => {
  const results = await request.get(`/v1/users/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUserWithSubs = async (id) => {
  const results = await request.get(`/v1/users/${id}/with-subs`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUserBookings = async (id) => {
  const results = await request.get(`/v1/users/${id}/bookings`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUserSubscriptions = async (id) => {
  const results = await request.get(`/v1/users/${id}/subscriptions`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUserDocuments = async (id) => {
  const results = await request.get(`/v1/users/${id}/docs`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const createUser = async (params) => {
  const body = {
    body: params,
  };
  const results = await request.post(`/v1/users`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const uploadUserDocument = async (userId, document, documentType) => {
  const fd = new FormData();
  fd.append('documents', document);
  fd.append('type', documentType);

  const body = {
    body: fd,
    header: {
      Accept: '*/*',
    },
  };
  const results = await request.post(`/v1/users/${userId}/docs`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteUserDocument = async (userId, documentId) => {
  const results = await request.remove(
    `/v1/users/${userId}/docs/${documentId}`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateUser = async (id, params) => {
  const body = {
    body: params,
  };
  const results = await request.patch(`/v1/users/${id}`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUserByEmail = async (email) => {
  const results = await request.get(`/v1/users/email/${email}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUserCreditScore = async (id) => {
  const results = await request.get(`/v1/users/${id}/creditscore`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateUserCreditScore = async (id, params) => {
  const body = {
    body: params,
  };
  const results = await request.post(`/v1/users/${id}/creditscore`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addUserBillingInfo = async (subscriberId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(
    `/v1/users/${subscriberId}/billingInfo`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editUserBillingInfo = async (userId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/users/${userId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addUserSubscription = async (id, payload) => {
  const params = {
    body: payload,
  };

  const results = await request.post(`/v1/users/${id}/subscriptions`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getUserBillingInfoByTin = async ({ userId, tin }) => {
  const results = await request.get(`/v1/users/${userId}/billingInfo/${tin}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getUsers,
  getUser,
  getUserBookings,
  getUserSubscriptions,
  getUserDocuments,
  createUser,
  getUsersEnums,
  uploadUserDocument,
  deleteUserDocument,
  updateUser,
  getUserByEmail,
  getUserCreditScore,
  updateUserCreditScore,
  addUserBillingInfo,
  editUserBillingInfo,
  getUserWithSubs,
  addUserSubscription,
  getUserBillingInfoByTin,
};
