import theme from 'config/theme';

export const BOOKINGS_STAGES = {
  27: {
    label: 'Prelead',
    styles: {
      backgroundColor: theme.palette.grays.gray300,
      color: theme.palette.grays.gray900,
    },
  },
  28: {
    label: 'Hot Prelead',
    styles: {
      backgroundColor: theme.palette.mints.mint200,
      color: theme.palette.mints.mint900,
    },
  },
  0: {
    label: 'Lead In',
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
  29: {
    label: 'No Answer',
    styles: {
      backgroundColor: theme.palette.violets.violet200,
      color: theme.palette.violets.violet900,
    },
  },
  30: {
    label: '2nd Call Attempt',
    styles: {
      backgroundColor: theme.palette.grays.gray400,
      color: theme.palette.grays.gray900,
    },
  },
  31: {
    label: 'Tel Contact No1',
    styles: {
      backgroundColor: theme.palette.grays.gray200,
      color: theme.palette.grays.gray900,
    },
  },
  32: {
    label: 'Follow Up',
    styles: {
      backgroundColor: theme.palette.purples.purple200,
      color: theme.palette.purples.purple900,
    },
  },
  1: {
    label: 'Docs Uploaded',
    styles: {
      backgroundColor: theme.palette.pinks.pink200,
      color: theme.palette.pinks.pink900,
    },
  },
  34: {
    label: 'Docs Received',
    styles: {
      backgroundColor: theme.palette.yellows.yellow200,
      color: theme.palette.yellows.yellow900,
    },
  },
  33: {
    label: 'Counter Offer',
    styles: {
      backgroundColor: theme.palette.blues.primaryBlue300,
      color: theme.palette.blues.primaryBlue900,
    },
  },
  2: {
    label: 'Rejected',
    styles: {
      backgroundColor: theme.palette.reds.red200,
      color: theme.palette.reds.red900,
    },
  },
  3: {
    label: 'Approved',
    styles: {
      backgroundColor: theme.palette.greens.codingGreen200,
      color: theme.palette.greens.codingGreen900,
    },
  },
  4: {
    label: 'instapayment',
    styles: {
      backgroundColor: theme.palette.blues.babyBlue200,
      color: theme.palette.blues.babyBlue900,
    },
  },
  5: {
    label: 'Customer Paid',
    styles: {
      backgroundColor: theme.palette.limes.lime200,
      color: theme.palette.limes.lime900,
    },
  },
  6: {
    label: 'Buy the Car',
    styles: {
      backgroundColor: theme.palette.teals.teal200,
      color: theme.palette.teals.teal900,
    },
  },
};
