const formatVehicleServiceType = (type) => {
  const types = {
    changeTires: 'Change Tires',
    plateLoss: 'Plate Loss',
    accident: 'Accident',
    carDamage: 'Car Damage',
    roadsideAssistance: 'Road Assistance',
    glassRepair: 'Glass Repair',
    carService: 'Car Service',
  };

  return types[type] || '-';
};

export default formatVehicleServiceType;
