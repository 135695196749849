import dynamic from 'next/dynamic';

const ReservationsGrid = dynamic(() =>
  import('./ReservationsGrid/ReservationsGrid')
);

const ReservationsFilters = dynamic(() =>
  import('./ReservationsFilters/ReservationsFilters')
);

const AddReservationModal = dynamic(() =>
  import('./AddReservationModal/AddReservationModal')
);

const ReservationForm = dynamic(() =>
  import('./ReservationForm/ReservationForm')
);
const DeleteConfirmationReservationsModal = dynamic(() =>
  import(
    './DeleteConfirmationReservationsModal/DeleteConfirmationReservationsModal'
  )
);

export {
  ReservationsGrid,
  ReservationsFilters,
  ReservationForm,
  AddReservationModal,
  DeleteConfirmationReservationsModal,
};
