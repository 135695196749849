import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Initial state
const initialState = {
  filters: {
    modalFiltersRules: undefined,
    modalConditions: undefined,
  },
};
// Actual Slice
export const arnmMaintenanceSlice = createSlice({
  name: 'arnmMaintenance',
  initialState,
  reducers: {
    filtersModalConditionArnmMaintenanceChangeSuccess: (state, { payload }) => {
      state.filters.modalConditions = payload;
      if (!payload?.rules?.length) {
        state.filters.modalFiltersRules = undefined;
      }
    },
    filtersModalRulesArnmMaintenanceChangeSuccess: (state, { payload }) => {
      state.filters.modalFiltersRules = payload;
    },
    resetModalArnmMaintenanceChangeSuccess: (state) => {
      state.filters.modalConditions = null;
      state.filters.modalFiltersRules = null;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  filtersModalConditionArnmMaintenanceChangeSuccess,
  filtersModalRulesArnmMaintenanceChangeSuccess,
  resetModalArnmMaintenanceChangeSuccess,
} = arnmMaintenanceSlice.actions;

export const selectArnmMaintenanceState = (state) => state.arnmMaintenance;

export default arnmMaintenanceSlice.reducer;

export const filtersModalConditionArnmMaintenanceChange = (conditions) => {
  return (dispatch) => {
    dispatch(filtersModalConditionArnmMaintenanceChangeSuccess(conditions));
  };
};
export const filtersModalRulesArnmMaintenanceChange = (rules) => {
  // console.log(rules);
  return (dispatch) => {
    dispatch(filtersModalRulesArnmMaintenanceChangeSuccess(rules));
  };
};
export const resetModalArnmMaintenanceChange = () => {
  return (dispatch) => {
    dispatch(resetModalArnmMaintenanceChangeSuccess());
  };
};
