import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { showError, request } from '@utils';
import { boardsServices } from '@services';
import { QUERY_KEYS } from 'constants/queryKeys';
import { BOARDS } from '@constants';
import {
  getColumns,
  getSellRequestsPayload,
  getSellRequestsQueryParams,
  getUrl,
  normaliseSellRequestsTasks,
} from './utils';
import { SELL_REQUESTS_BOARD, SELL_REQUESTS_PAGE_SIZE } from './constants';

export const useSellRequestsOperations = () => {
  const router = useRouter();
  const [filters, setFilters] = useState([]);
  const page = router.query?.page || 1;
  const orderBy = router.query?.orderBy || 'createdAt';
  const order = router.query?.order || 'desc';
  const searchTerm = router.query?.searchTerm;
  const status = router.query?.status?.split(',').map((item) => Number(item));
  const archived = router.query?.archived;

  const board = SELL_REQUESTS_BOARD;

  const params = {
    page,
    orderBy,
    order,
    searchTerm,
    status,
    archived,
  };

  const cleanParams = getSellRequestsQueryParams({
    ...params,
    boardType: BOARDS.SELL_REQUESTS,
  });

  const sellRequestsResponse = useQuery(
    [QUERY_KEYS.GET_SELL_REQUESTS_KEY, cleanParams, filters],
    () =>
      boardsServices.getBillingBoardsFilters({
        boardId: board.type,
        params: getSellRequestsQueryParams(cleanParams),
        payload: getSellRequestsPayload({
          ...cleanParams,
          filters,
          boardId: board.id,
          boardType: board.type,
        }),
      })
  );

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }));
  };

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel });

    router.push(url);
  };

  const handleFiltersSubmit = (payload) => {
    setFilters(payload.value);
  };

  const handleFiltersChange = (payload) => {
    if (!payload?.rules?.length) {
      setFilters([]);
    }
  };

  const sellRequestsTasks = normaliseSellRequestsTasks(
    sellRequestsResponse?.data?.data || []
  );

  const statuses = board.statusOrder.reduce((acc, cur) => {
    acc.push({
      text: board.statuses[cur].label,
      value: Number(cur),
    });
    return acc;
  }, []);

  const _addTask = async (payload = {}) => {
    const data = {
      body: payload,
    };
    const response = await request.post(`/v1/www/vehicle-sales`, data);

    if (response?.error) {
      showError(response?.message);
    }
    await sellRequestsResponse.refetch();
  };

  const updateTask = async (payload = {}) => {
    try {
      if (!payload?.id) return;

      const taskId = payload?.id;
      await boardsServices.updateTask(taskId, payload);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (sellRequestsResponse.isError) {
      showError(sellRequestsResponse?.error);
    }
  }, [sellRequestsResponse.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    page,
    pageSize: SELL_REQUESTS_PAGE_SIZE,
    total: sellRequestsResponse?.data?.meta?.total,
    loading: sellRequestsResponse?.isLoading,
    error: sellRequestsResponse?.error,
    isSuccess: sellRequestsResponse?.isSuccess,
    sellRequests: sellRequestsTasks?.tasks,
    totalFinalPrice: sellRequestsTasks?.totalFinalPrice,
    board,
    statuses,
    columns: getColumns(sellRequestsTasks?.tasks || [], board),
    handlePageChange,
    handleSortBy,
    refresh: sellRequestsResponse.refetch,
    addTask: _addTask,
    updateTask,
    handleFiltersSubmit,
    handleFiltersChange,
  };
};
