import dynamic from 'next/dynamic';

const UsersGrid = dynamic(() => import('./UsersGrid/UsersGrid'));
const UserDetails = dynamic(() => import('./UserDetails/UserDetails'));
const UserMenu = dynamic(() => import('./UserMenu/UserMenu'));
const RecentActions = dynamic(() => import('./RecentActions/RecentActions'));
const DocumentsGrid = dynamic(() => import('./DocumentsGrid/DocumentsGrid'));
const AddNewUserModal = dynamic(() =>
  import('./AddNewUserModal/AddNewUserModal')
);
const EditUserModal = dynamic(() => import('./EditUserModal/EditUserModal'));
const UploadUserDocument = dynamic(() =>
  import('./UploadUserDocument/UploadUserDocument')
);
const UserBillingInfos = dynamic(() =>
  import('./UserBillingInfos/UserBillingInfos')
);

const UsersBookingsGrid = dynamic(() =>
  import('./UsersBookingsGrid/UsersBookingsGrid')
);
const CreditScoreForm = dynamic(() =>
  import('./CreditScoreForm/CreditScoreForm')
);
const SubscriberSubsGrid = dynamic(() =>
  import('./SubscriberSubsGrid/SubscriberSubsGrid')
);
const CreateSubscriptionModal = dynamic(() =>
  import('./CreateSubscriptionModal/CreateSubscriptionModal')
);
const AddSubscriptionModal = dynamic(() =>
  import('./AddSubscriptionModal/AddSubscriptionModal')
);
const UserSellRequestsGrid = dynamic(() =>
  import('./UserSellRequestsGrid/UserSellRequestsGrid')
);
const UserLeasingBuyoutsGrid = dynamic(() =>
  import('./UserLeasingBuyoutsGrid/UserLeasingBuyoutsGrid')
);

export {
  UsersGrid,
  UserDetails,
  UserMenu,
  RecentActions,
  DocumentsGrid,
  AddNewUserModal,
  EditUserModal,
  UploadUserDocument,
  UserBillingInfos,
  UsersBookingsGrid,
  CreditScoreForm,
  SubscriberSubsGrid,
  CreateSubscriptionModal,
  AddSubscriptionModal,
  UserSellRequestsGrid,
  UserLeasingBuyoutsGrid,
};
