/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { useSearchParams } from 'next/navigation';
import { showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';

import { agentsServices, communicationServices } from '@services';
import {
  getThreadPayload,
  handleAssignees,
  handleEmail,
  handleHistory,
  handleThreadData,
} from './utils';

export const useEmailOperations = () => {
  const searchParams = useSearchParams();
  const {
    back,
    query: { threadId },
  } = useRouter();
  const mailboxId = searchParams.get('mailboxId');

  const threadResponse = useQuery(
    [QUERY_KEYS.GET_MAILBOX_THREAD, { mailboxId, threadId }],
    () => {
      const params = {
        page: 1,
        pageSize: 300,
        orderBy: 'messageDate',
        order: 'asc',
      };

      const payload = getThreadPayload({ threadId, mailboxId });
      return communicationServices.getEmails({
        params,
        payload,
      });
    },
    {
      enabled: !!(mailboxId && threadId),
    }
  );
  const lastEmailId =
    threadResponse?.data?.data?.length >= 1
      ? threadResponse.data.data[threadResponse.data.data.length - 1].id
      : undefined;

  const mailResponse = useQuery(
    [QUERY_KEYS.GET_MAIL, lastEmailId],
    () => communicationServices.getEmailById(lastEmailId),
    { enabled: !!lastEmailId }
  );

  const historyResponse = useQuery(
    [QUERY_KEYS.GET_MAIL_HISTORY, lastEmailId],
    () => communicationServices.getEmailHistoryById(lastEmailId),
    { enabled: !!lastEmailId }
  );

  const assigneesResponse = useQuery([QUERY_KEYS.GET_AGENTS_KEY], () =>
    agentsServices.getAgents({
      page: 1,
      pageSize: 3000,
    })
  );

  useEffect(() => {
    if (threadResponse?.error) {
      showError(threadResponse?.error);
    }
  }, [threadResponse?.error]);

  useEffect(() => {
    if (assigneesResponse?.error) {
      showError(assigneesResponse?.error);
    }
  }, [assigneesResponse?.error]);

  useEffect(() => {
    if (mailResponse?.error) {
      showError(mailResponse?.error);
    }
  }, [mailResponse?.error]);

  useEffect(() => {
    if (historyResponse?.error) {
      showError(historyResponse?.error);
    }
  }, [historyResponse?.error]);

  const refetch = async () => {
    await threadResponse?.refetch();
    await mailResponse?.refetch();
    historyResponse?.refetch();
  };
  const thread = handleThreadData(threadResponse?.data?.data || []);
  const assignees = handleAssignees(assigneesResponse?.data?.data || []);
  const history = handleHistory(historyResponse?.data?.data || []);
  const email = handleEmail(mailResponse?.data?.data);
  const isFetching =
    threadResponse?.isFetching ||
    assigneesResponse?.isFetching ||
    mailResponse?.isFetching ||
    historyResponse?.isFetching;

  return {
    loading:
      threadResponse?.isLoading ||
      assigneesResponse?.isLoading ||
      mailResponse?.isLoading ||
      historyResponse?.isLoading,
    isFetching,
    emailId: email?.id,
    email,
    assignees,
    goBack: back,
    history,
    thread,
    threadId,
    refetch,
  };
};
