import { RESERVATIONS_TYPES } from '@constants';
import { formatDate, sortArray } from '@utils';
import { ENTITIES_PERMISIONS } from 'components/common/PermissionsGate/constants';

const allowedPermissions = [
  ENTITIES_PERMISIONS.ReadBookings,
  ENTITIES_PERMISIONS.ReadReservations,
];

export const normalizeReservations = (data = []) => {
  if (!data.length > 0) {
    return [];
  }

  const reservations = data.map((item) => {
    return {
      ...item,
      agent: item?.agent || '-',
      note: item?.note || '',
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      createdBy: item?.createdBy || '-',
      subscriptionId: item?.subscriptionId || '-',
      subscriptionType: item?.subscriptionType || null,
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY HH:mm')
        : '-',
      updatedBy: item?.updatedBy || '-',
      vehicleInternalId: item?.vehicleInternalId || '',
      vehicleName: item?.vehicleName || '',
      vehiclePlate: item?.vehiclePlate || '',
    };
  });

  return reservations;
};

export const normalizeReservationsEnums = (data = []) => {
  if (!data?.types?.length) {
    return [];
  }
  return data?.types.map((item) => {
    return {
      text: RESERVATIONS_TYPES?.[item.name]?.text || item.name,
      value: item.id,
    };
  });
};

export const normalizeReservationsAgents = (data = []) => {
  if (!data?.length) {
    return [];
  }
  return data
    .map((item) => {
      return {
        isActive: item?.isActive,
        text:
          item.firstName && item.lastName
            ? `${item?.firstName} ${item?.lastName}`
            : item.email,
        value: item.email,
        permissions: item.permissions,
      };
    })
    ?.sort((a, b) => a.text.localeCompare(b.text))
    ?.filter((agent) =>
      allowedPermissions.every((permission) =>
        agent.permissions.includes(permission)
      )
    );
};

export const normalizeAssigneeData = (data = []) =>
  data
    .map((item) => ({
      isActive: item?.isActive,
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
      shortName:
        item.firstName && item.lastName
          ? `${item?.firstName[0].toUpperCase()}${item?.lastName[0].toUpperCase()}`
          : item.email[0].toUpperCase(),
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));

export const normalizeReservationData = (data) => {
  if (!data) {
    return {};
  }

  return {
    ...data,
    agent: data?.agent || '',
    type: data?.type || '',
    subscriptionType: data?.subscriptionType || '',
    createdAt: data.createdAt ? formatDate(data.createdAt, 'DD/MM/YYYY') : '-',
    updatedAt: data.updatedAt ? formatDate(data.updatedAt, 'DD/MM/YYYY') : '-',
    comments:
      !!data.comments && !!data.comments.length
        ? sortArray(data?.comments, 'createdAt', 'desc')?.map((item) => {
            return {
              ...item,
              date: item.createdAt
                ? formatDate(item.createdAt, 'DD/MM/YYYY')
                : '-',
              time: item?.createdAt
                ? formatDate(item?.createdAt, 'HH:mm')
                : '-',
              name: item?.createdBy,
              comment: item?.message,
            };
          })
        : [],
  };
};

export const normalizeReservationHistory = (data) => {
  if (!data) {
    return [];
  }

  const history = data
    ?.map((item) => {
      const isType = item?.field?.[0] === 'Type';

      if (item?.field?.[0] === 'Comments') return null;

      if (isType) {
        const oldValue = Object.values(RESERVATIONS_TYPES).find(
          (type) => type.value === item?.from
        )?.text;
        const newValue = Object.values(RESERVATIONS_TYPES).find(
          (type) => type.value === item?.to
        )?.text;

        return {
          ...item,
          createdBy: item?.actor,
          fieldName: item?.field?.[0] || '',
          newValue,
          oldValue,
        };
      }

      return {
        ...item,
        createdBy: item?.actor,
        fieldName: item?.field?.[0] || '',
        newValue: item?.to || '',
        oldValue: item?.from || '-',
      };
    })
    ?.filter((item) => item);
  return history;
};
