import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { selectBookingsState, addSnapshot, clearSnapshot } from '@store';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import {
  /* agentsServices, */
  bookingsServices,
} from '@services';
import { useLayout } from 'hooks/layout';
import { QUERY_KEYS } from 'constants/queryKeys';
import { URLS } from 'layouts/DrawerLayout/constants';
import {
  getBookingsCardColumns,
  getBookingsPayload,
  // handleAssigneeData,
  handleBookings,
} from './utils';

export const BOOKINGS_PAGE_SIZE = 200;

export const useBookingsOperations = (fetchOnMount = true) => {
  const { bookingSnapshot } = useSelector(selectBookingsState);
  const { push, query, isReady } = useRouter();
  const {
    page = 1,
    orderBy = 'created_at',
    order = 'desc',
    searchTerm = '',
    origin,
    contractType,
    contractPeriod,
    stage,
    assignee,
  } = query;
  const { tenant } = useLayout();
  const dispatch = useDispatch();

  const cleanParams = getCleanedUrlParams({
    page,
    orderBy,
    order,
    tenant,
    pageSize: BOOKINGS_PAGE_SIZE,
    origin,
    contract_type: contractType,
    stage,
    assignee,
    searchTerm,
    contract_period: contractPeriod,
  });

  const bookingsResponse = useQuery(
    [QUERY_KEYS.GET_BOOKINGS_KEY, cleanParams],
    () => {
      const params = {
        page,
        orderBy,
        order,
        pageSize: BOOKINGS_PAGE_SIZE,
      };
      const payload = getBookingsPayload(cleanParams);
      return bookingsServices.getBookings({ params, payload });
    },
    { enabled: fetchOnMount && isReady }
  );

  // const assigneesResponse = useQuery(
  //   QUERY_KEYS.GET_AGENTS_KEY,
  //   () =>
  //     agentsServices.getAgents({
  //       page: 1,
  //       pageSize: 3000,
  //     }),
  //   { enabled: fetchOnMount && isReady }
  // );

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...query, ...sortByModel }, URLS.bookings);
    push(url);
  };

  const handlePageChange = (newPage) => {
    const currentPage = query.page || '1';
    if (newPage !== currentPage) {
      push(getUrl({ ...query, page: newPage }, URLS.bookings));
    }
  };

  const handleAddSnapshot = useCallback(
    async (payload) => {
      dispatch(addSnapshot(payload));
    },
    [dispatch]
  );

  const handleClearSnapshot = useCallback(async () => {
    dispatch(clearSnapshot());
  }, [dispatch]);

  useEffect(() => {
    if (bookingsResponse?.error) {
      showError(bookingsResponse?.error);
    }
  }, [bookingsResponse?.error]);

  const bookings = handleBookings(bookingsResponse?.data?.data || []);
  //  const assignees = handleAssigneeData(assigneesResponse?.data?.data);
  const columns = getBookingsCardColumns(bookings || []);

  return {
    page,
    total: bookingsResponse?.data?.meta?.total,
    pageSize: BOOKINGS_PAGE_SIZE,
    loading: bookingsResponse.isFetching,
    bookings,
    columns,
    // assignees,
    bookingSnapshot,
    sortBy: handleSortBy,
    getBookings: bookingsResponse.refetch,
    nextPage: handlePageChange,
    addSnapshot: handleAddSnapshot,
    clearSnapshot: handleClearSnapshot,
  };
};
