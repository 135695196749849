/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { agentsServices } from '@services';
import { getUrl, showError } from '@utils';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCommunication,
  getMailboxes,
  getTotals,
  markAsReadUnread,
  moveToTeam,
  setStatus,
  assignPerson,
  selectCommunicationState,
  initializeLoad,
  initializeMailboxes,
} from '@store';
import { computeEmailListPayload } from '@helpers';
import { SIDE_FILTERS_ENUM } from 'components/communication/CommunicationSideBar/constants';
import { URLS } from 'layouts/DrawerLayout/constants';

export const useCommunicationOperations = (fetchOnMount = false) => {
  const {
    communicationEmails,
    pageCount,
    loading,
    loaded,
    totals,
    errorMessage,
    mailBoxes,
    mailBoxesLoaded,
    pageSize,
  } = useSelector(selectCommunicationState);
  const {
    push,
    isReady,
    query: {
      orderBy = 'messageDate',
      order = 'desc',
      side = 1,
      mailboxId = '',
      page = '1',
      assignee = undefined,
      teams = undefined,
      status = undefined,
      searchTerm = '',
    },
  } = useRouter();
  const [assignees, setAssignees] = useState([]);
  const dispatch = useDispatch();

  const _getMailBoxes = useCallback(async () => {
    await dispatch(getMailboxes());
  }, []);

  const _getEmails = useCallback(async () => {
    if (!mailboxId) return;

    const payload = computeEmailListPayload({
      assignee,
      team: teams,
      status,
      side,
      mailboxId,
      isTop: true,
      searchTerm,
    });
    await dispatch(
      getCommunication(
        {
          orderBy,
          order,
          page,
          pageSize,
          mailboxId,
        },
        payload
      )
    );
  }, [
    page,
    orderBy,
    mailboxId,
    order,
    assignee,
    teams,
    status,
    side,
    searchTerm,
  ]);

  const _fetchAssignees = async () => {
    try {
      const { data } = await agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
      setAssignees(
        data.map((item) => ({
          ...item,
          value: item.id,
          text:
            item.firstName && item.lastName
              ? `${item.firstName} ${item?.lastName}`
              : item.email,
        }))
      );
    } catch (error) {
      showError(error);
    }
  };
  const _getTotals = useCallback(async () => {
    const payload = computeEmailListPayload({
      mailboxId,
    });

    await dispatch(
      getTotals(
        {
          orderBy,
          order,
          page,
          pageSize,
          noData: true,
          mailboxId,
        },
        payload
      )
    );
  }, [page, orderBy, order, mailboxId]);

  const _onMarkAsReadUnread = (emailIds, isRead) => {
    dispatch(
      markAsReadUnread({
        emailIds,
        action: { read: isRead },
      })
    );
  };

  const _onMoveToTeam = (emailIds, team) => {
    const isNone = team === 'None';
    dispatch(
      moveToTeam({
        emailIds,
        action: { team: isNone ? null : team },
      })
    );
  };

  const _onSetStatus = (emailIds, statusValue) => {
    dispatch(
      setStatus({
        emailIds,
        action: { status: statusValue },
      })
    );
  };

  const _onAssignPerson = useCallback(
    async (emailIds, person = {}) => {
      await dispatch(
        assignPerson({
          emailIds,
          action: {
            assignee: person?.id
              ? {
                  id: person?.id,
                  email: person?.email,
                  firstName: person?.firstName,
                  lastName: person?.lastName,
                }
              : null,
          },
        })
      );
      if (SIDE_FILTERS_ENUM['All Emails'] === Number(side)) {
        await _getTotals();
      } else {
        await _getEmails();
      }
    },
    [side]
  );

  const _nextPage = useCallback(
    async (currentPage) => {
      const params = {
        orderBy,
        assignee,
        teams,
        status,
        order,
        side,
        page: currentPage.toString(),
        mailboxId,
        searchTerm,
      };
      const url = getUrl(params, URLS.communication);

      push(url, undefined, { shallow: true });
    },
    [page, orderBy, mailboxId, order, assignee, teams, status, side, searchTerm]
  );

  const _initialize = () => {
    dispatch(initializeLoad());
  };

  useEffect(() => {
    if (mailBoxes?.length && mailBoxesLoaded && !mailboxId) {
      push({
        pathname: URLS.communication,
        query: {
          page,
          orderBy,
          mailboxId: mailBoxes?.[0]?.value,
          order,
          assignee,
          teams,
          status,
          side,
          searchTerm,
        },
      });
      dispatch(initializeMailboxes());
    }
  }, [
    mailboxId,
    mailBoxes,
    mailBoxesLoaded,
    page,
    orderBy,
    order,
    assignee,
    teams,
    status,
    searchTerm,
    side,
  ]);

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _fetchAssignees();
      if (!mailBoxes?.length) {
        _getMailBoxes();
      } else {
        _getEmails();
      }
    }
  }, [
    fetchOnMount,
    isReady,
    assignee,
    teams,
    status,
    side,
    page,
    mailboxId,
    searchTerm,
    mailBoxes,
  ]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    return () => _initialize();
  }, []);

  return {
    side,
    assignees,
    communicationEmails,
    pageCount,
    page,
    pageSize,
    totals,
    loading,
    loaded,
    mailBoxes,
    getEmails: _getEmails,
    onMarkAsReadUnread: _onMarkAsReadUnread,
    nextPage: _nextPage,
    onMoveToTeam: _onMoveToTeam,
    onSetStatus: _onSetStatus,
    onAssignPerson: _onAssignPerson,
    getTotals: _getTotals,
    initialize: _initialize,
  };
};
