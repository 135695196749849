import { useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useGridApiRef } from '@mui/x-data-grid-premium';

export const useGridState = ({ saveGridState }) => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();
      dispatch(
        saveGridState({
          gridState: currentState,
        })
      );
    }
  }, [apiRef, dispatch, saveGridState]);

  useLayoutEffect(() => {
    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  apiRef?.current?.subscribeEvent?.('columnsChange', saveSnapshot);

  return { apiRef, saveSnapshot };
};

export default useGridState;
