import { request } from '@utils';
import { FILTER_RULE_ENUMS } from '@constants';

const getAvailability = async ({ params, payload }) => {
  const body = {
    body: payload,
  };

  const results = await request.post(
    `/v1/availability/filter?${new URLSearchParams(params)}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getAvailabilityEnums = async () => {
  const results = await Promise.all([
    request.get(`/v1/availability/enums`),
    request.get(`/v1/reservations/enums`),
    request.get(`/v1/catalog/vehicles/enums`),
    request.post(`/v1/servicePoints/filter?page=1&pageSize=1000`, {
      body: {
        type: FILTER_RULE_ENUMS.EmptyRuleType,
      },
    }),
  ]);

  if (results?.error) {
    return Promise.reject(results?.message);
  }
  return {
    statuses: results[0]?.data?.statuses || [],
    reservationsTypes: results[1]?.data?.types || [],
    models: results[2]?.data?.models || [],
    bodyTypes: results[2]?.data?.bodyTypes || [],
    skuTypes: results[2]?.data?.skuTypes || [],
    colors: results[2]?.data?.colors || [],
    transmissions: results[2]?.data?.transmissions || [],
    fuelTypes: results[2]?.data?.fuelTypes || [],
    engineTypes: results[2]?.data?.engineTypes || [],
    servicePoints: results[3]?.data || [],
  };
};

const editAvailability = async (id, params) => {
  const payload = {
    body: params,
  };
  const results = await request.patch(`/v1/availability/${id}`, payload);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportFile = async ({ params, payload }) => {
  const body = {
    body: payload,
    responseType: 'text',
  };

  const results = await request.post(
    `/v1/availability/export?${new URLSearchParams({
      ...params,
      type: 'csv',
    })}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const exportUTRFile = async (params) => {
  const body = {
    body: { ...params },
  };
  const results = await request.post(`/v1/vehicles/export/utr/async`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getAvailability,
  exportFile,
  getAvailabilityEnums,
  editAvailability,
  exportUTRFile,
};
