/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';

import {
  selectArnmRepairsState,
  filtersModalRulesArnmRepairsChange,
  resetModalArnmRepairsChange,
  filtersModalConditionArnmRepairsChange,
} from '@store';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import {
  agentsServices,
  boardsServices,
  servicePointsServices,
} from '@services';
import { BOARDS, BOARDS_ID, SERVICE_POINTS_PAGE_SIZE } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { URLS } from 'layouts/DrawerLayout/constants';
import {
  getArnmRepairsColumns,
  getArnmRepairsPayload,
  handleArnmRepairsData,
  handleAssigneeData,
  handleServicePoints,
} from './utils';

export const ARM_REPAIRS_PAGE_SIZE = 1000;

export const useArnmRepairsOperations = () => {
  const { isReady, push, query } = useRouter();
  const { filters } = useSelector(selectArnmRepairsState);
  const dispatch = useDispatch();

  const page = query?.page || '1';
  const orderBy = query?.orderBy || 'createdAt';
  const order = query?.order || 'desc';

  const cleanedParams = getCleanedUrlParams({
    ...omit(query, ['view']),
    page,
    orderBy,
    order,
  });

  const assigneesResponse = useQuery(
    QUERY_KEYS.GET_AGENTS_KEY,
    () => {
      return agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
    },
    { enabled: isReady }
  );

  const servicePointsResponse = useQuery(
    QUERY_KEYS.GET_SERVICE_POINTS_KEY,
    () => {
      return servicePointsServices.getServicePoints({
        page: 1,
        pageSize: SERVICE_POINTS_PAGE_SIZE,
      });
    },
    { enabled: isReady }
  );
  const repairsResponse = useQuery(
    [
      QUERY_KEYS.GET_ARNM_REPAIRS_TASKS_KEY,
      { cleanedParams, filters: filters.modalFiltersRules },
    ],
    () => {
      const urlParams = getCleanedUrlParams({
        page,
        orderBy,
        order,
        pageSize: ARM_REPAIRS_PAGE_SIZE,
        boardType: BOARDS.ARM_REPAIRS,
      });
      const payload = getArnmRepairsPayload(
        cleanedParams,
        filters.modalFiltersRules
      );
      return boardsServices.getArnmBoardsFilters({
        boardId: BOARDS_ID.ARM_REPAIRS,
        urlParams,
        payload,
      });
    },
    { enabled: isReady && assigneesResponse?.isFetched }
  );

  useEffect(() => {
    if (repairsResponse?.error) {
      showError(repairsResponse?.error);
    }
  }, [repairsResponse?.error]);

  useEffect(() => {
    if (assigneesResponse?.error) {
      showError(assigneesResponse?.error);
    }
  }, [assigneesResponse?.error]);
  useEffect(() => {
    if (servicePointsResponse?.error) {
      showError(servicePointsResponse?.error);
    }
  }, [servicePointsResponse?.error]);

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...query, ...sortByModel }, URLS.arnmRepairs);
    push(url);
  };

  const handlePageChange = (newPage) => {
    const currentPage = query.page || '1';
    if (newPage !== currentPage) {
      push(getUrl({ ...query, page: newPage }, URLS.arnmRepairs));
    }
  };
  const handleChangeRules = (rules) => {
    dispatch(filtersModalRulesArnmRepairsChange(rules));
  };

  const resetModalChange = useCallback(() => {
    dispatch(resetModalArnmRepairsChange());
  }, [dispatch]);

  const handleFiltersModalChange = useCallback(
    (conditions) => {
      dispatch(filtersModalConditionArnmRepairsChange(conditions));
    },
    [dispatch]
  );

  const assignees = handleAssigneeData(assigneesResponse?.data?.data || []);
  const arnmRepairs = handleArnmRepairsData(
    repairsResponse?.data?.data || [],
    assigneesResponse?.data?.data || []
  );
  const servicePoints = handleServicePoints(
    servicePointsResponse?.data?.data || []
  );
  const columns = getArnmRepairsColumns(arnmRepairs);
  // console.log(arnmRepairs, columns);

  return {
    page,
    total: repairsResponse?.data?.meta?.total,
    pageSize: ARM_REPAIRS_PAGE_SIZE,
    loading:
      assigneesResponse?.isLoading ||
      assigneesResponse?.isLoading ||
      servicePointsResponse?.isLoading,
    arnmRepairs,
    assignees,
    servicePoints,
    columns,
    filters,
    getArnmRepairs: repairsResponse.refetch,
    nextPage: handlePageChange,
    sortBy: handleSortBy,
    handleFiltersModalChange,
    handleChangeRules,
    resetModalChange,
  };
};
