const computeContractPeriods = () => {
  const months = [{ text: '1 μήνας', value: '1' }];

  for (let i = 2; i < 49; i++) {
    months.push({ text: `${i} μήνες`, value: `${i}` });
  }
  return months;
};

export default computeContractPeriods;
