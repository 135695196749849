import { request } from '@utils';

const addSubscription = async ({ subscriptionId, body }) => {
  const params = {
    body,
  };

  const results = await request.post(
    `/v1/subscriptions/${subscriptionId}/subscribers`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { addSubscription };
