import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { boardsServices, usersServices } from '@services';
import { BOARDS_ID, GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import {
  handleUser,
  handleUserWithSubs,
  handleUserSubscriptions,
} from './users.handlers';

// Initial state
const initialState = {
  loading: true,
  hasErrors: false,
  errorMessage: '',
  users: [],
  user: {},
  total: 0,
  firstItem: 0,
  lastItem: LIMIT,
  pageCount: 0,
  pageSize: LIMIT,
  userWithSubs: {},
  subscriptions: [],
};
// Actual Slice
export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUser: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchUserSuccess: (state, { payload }) => {
      state.user = handleUser(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchUserFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchUserWithSubs: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchUserWithSubsSuccess: (state, { payload }) => {
      state.userWithSubs = handleUserWithSubs(payload?.data);
      state.loading = false;
    },
    fetchUserWithSubsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchUserSubscriptions: (state) => {
      state.loading = true;
      state.hasErrors = true;
      state.errorMessage = '';
      state.subscriptions = [];
    },
    fetchUserSubscriptionsSuccess: (state, { payload }) => {
      state.subscriptions = handleUserSubscriptions(
        payload?.response?.data,
        payload.subscriberId
      );
      state.loading = false;
    },
    fetchUserSubscriptionsFailure: (state, { payload }) => {
      state.subscriptions = [];
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchUser,
  fetchUserSuccess,
  fetchUserFailure,
  fetchUserWithSubs,
  fetchUserWithSubsSuccess,
  fetchUserWithSubsFailure,
  fetchUserSubscriptions,
  fetchUserSubscriptionsSuccess,
  fetchUserSubscriptionsFailure,
} = usersSlice.actions;

export const selectUsersState = (state) => state.users;

export default usersSlice.reducer;

export const getUser = (id) => {
  return async (dispatch) => {
    dispatch(fetchUser());

    try {
      const user = await usersServices?.getUser(id);
      const bookings = await usersServices?.getUserBookings(id);
      const subscriptions = await usersServices?.getUserSubscriptions(id);
      const creditScore = await usersServices?.getUserCreditScore(id);
      const sellRequestsPayload = {
        page: 1,
        pageSize: 1000,

        type: 0,
        field: null,
        operator: null,
        boardId: BOARDS_ID.SEL_VEHICLES,
        value: [
          {
            type: 5,
            field: 'boardId',
            operator: 0,
            value: BOARDS_ID.SEL_VEHICLES,
          },
          {
            type: 5,
            field: 'customFields.userId',
            operator: 0,
            value: id,
          },
        ],
      };
      const sellRequests = await boardsServices.getBoardsFilters(
        sellRequestsPayload
      );

      const leasingBuyoutsPayload = {
        page: 1,
        pageSize: 1000,

        type: 0,
        field: null,
        operator: null,
        boardId: BOARDS_ID.BUY_OUT,
        value: [
          {
            type: 5,
            field: 'boardId',
            operator: 0,
            value: BOARDS_ID.BUY_OUT,
          },
          {
            type: 5,
            field: 'customFields.userId',
            operator: 0,
            value: id,
          },
        ],
      };
      const leasingBuyouts = await boardsServices.getBoardsFilters(
        leasingBuyoutsPayload
      );

      dispatch(
        fetchUserSuccess({
          details: user?.data,
          bookings: bookings?.data,
          subscriptions: subscriptions?.data,
          creditScore: creditScore?.data,
          sellRequests: sellRequests?.data,
          leasingBuyouts: leasingBuyouts?.data,
        })
      );
    } catch (error) {
      dispatch(fetchUserFailure(error));
    }
  };
};

export const getUserWithSubs = (payload) => {
  return async (dispatch) => {
    dispatch(fetchUserWithSubs());
    try {
      const response = await usersServices?.getUserWithSubs(payload);

      dispatch(fetchUserWithSubsSuccess(response));
    } catch (error) {
      dispatch(fetchUserWithSubsFailure(error));
    }
  };
};

export const getUserSubscriptions = (payload) => {
  return async (dispatch) => {
    dispatch(fetchUserSubscriptions());

    try {
      const response = await usersServices?.getUserSubscriptions(payload);
      dispatch(
        fetchUserSubscriptionsSuccess({ response, subscriberId: payload })
      );
    } catch (error) {
      dispatch(fetchUserSubscriptionsFailure(error));
    }
  };
};
