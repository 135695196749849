import { formatDate } from '@utils';

const handleJobAssigneeName = (jobUserEmail = '', assignees = []) => {
  if (!jobUserEmail) {
    return '';
  }

  const user = assignees.find((assignee) => assignee.email === jobUserEmail);
  const userNameExists = !!user && !!user.firstName && !!user.lastName;

  if (userNameExists) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user?.email) {
    return user.email;
  }
  return 'Unknown User';
};

export const handleExportDataJobsData = (jobs = [], assignees = []) => {
  if (!jobs.length) {
    return [];
  }

  const newJobs = jobs.map((job) => {
    return {
      ...job,
      createdBy: handleJobAssigneeName(job.createdBy, assignees),
      createdAt: job.createdAt
        ? formatDate(job.createdAt, 'DD/MM/YYYY HH:mm')
        : '-',
    };
  });

  return newJobs;
};
