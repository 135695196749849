const generateOrdinalNumbers = (length = 48) => {
  const numbers = [];

  for (let i = 1; i <= length; i++) {
    numbers.push({ text: `${i}ος`, value: i });
  }
  return numbers;
};

export default generateOrdinalNumbers;
