import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDetail, selectLoginState } from '@store';
import { useLayout } from '@hooks';
import { useBookingsOperations } from '../bookings/useBookingsOperations';

export const useConsolidateStoreOperations = (fetchOnMount) => {
  const { addSnapshot } = useBookingsOperations(false);
  const { setTenant } = useLayout();
  const { user } = useSelector(selectLoginState);
  const dispatch = useDispatch();

  const _fetchUser = useCallback(async () => {
    if (!user) {
      await dispatch(fetchUserDetail());
    }
  }, [dispatch, user]);

  const _setTenant = useCallback(() => {
    const { tenant } = localStorage;
    if (typeof window !== 'undefined' && typeof tenant !== 'undefined') {
      setTenant(JSON.parse(tenant));
    }
  }, [setTenant]);

  const _setBookingSnapshots = useCallback(() => {
    const cachedUser =
      typeof window !== 'undefined' &&
      localStorage.user &&
      localStorage.user !== 'undefined' &&
      JSON.parse(localStorage.user);
    const snapshot =
      typeof window !== 'undefined' &&
      localStorage.bookingSnapshot &&
      JSON.parse(localStorage.bookingSnapshot);
    if (cachedUser && snapshot?.[cachedUser.email]) {
      addSnapshot(snapshot[cachedUser.email]);
    }
  }, [addSnapshot]);

  useEffect(() => {
    if (fetchOnMount) {
      _fetchUser();
      _setBookingSnapshots();
    }
  }, [_fetchUser, _setBookingSnapshots, fetchOnMount]);

  return {
    fetchUser: _fetchUser,
    setPersistentTenant: _setTenant,
  };
};
