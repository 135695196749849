import { groupBy, isNumber, omit } from 'lodash';

import {
  CONTRACT_TYPES,
  FILTER_OPERATOR_ENUMS,
  FILTER_RULE_ENUMS,
  TENANT_NAMES,
} from '@constants';
import { fixedNumber, formatAmount, formatDate, timeFromNow } from '@utils';
import { BOOKINGS_STAGES } from 'components/bookings/constants';
import theme from 'config/theme';
import { URLS } from 'layouts/DrawerLayout/constants';

export const getBookingsCardColumns = (tasks = []) => {
  const order = [27, 28, 0, 29, 30, 31, 32, 1, 34, 33, 2, 3, 4, 5, 6];
  const groupedByStatus = groupBy(tasks, 'stage');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: BOOKINGS_STAGES[cur]?.label,
      cards: groupedByStatus[cur] || [],
      styles: BOOKINGS_STAGES[cur]?.styles,
      value: true,
      label: BOOKINGS_STAGES[cur]?.label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const handleBookings = (data = []) => {
  return data.map((booking) => {
    const contractType =
      booking?.contractType === CONTRACT_TYPES.open ? 'Open' : 'Fixed';
    const date = booking?.created_at
      ? formatDate(booking?.created_at, 'DD/MM/YYYY')
      : '-';
    const contractPeriod = `${booking.contract_period} Month`;

    return {
      id: booking?.id,
      href: `${URLS.bookings}/${booking?.id}`,
      description: booking?.vehicle?.title || '-',
      heading: `${booking?.first_name} ${booking?.last_name}`,
      stage: booking.stage,
      date,
      contractType,
      fullName: `${booking?.first_name} ${booking?.last_name}`,
      created_at: date,
      updated_at: booking?.updated_at
        ? formatDate(booking?.updated_at, 'DD/MM/YYYY HH:mm')
        : '-',
      timeAgo: timeFromNow(booking?.created_at),
      email: booking?.email,
      pipeDriveId: booking?.pipedrive?.id,
      pipeDriveUrl: booking?.pipedrive?.url,
      is_active: booking?.is_active || '',
      region: booking?.region || '-',
      contract: booking?.contract || '-',
      contractPeriod,
      phoneNumber: booking?.phone_number || '-',
      vehicle: booking?.vehicle?.title || '-',
      origin: booking?.origin,
      skuId: booking?.vehicle?.id,
      tenant: TENANT_NAMES[booking?.tenant],
      price: booking?.products?.[1]?.finalPrice?.number
        ? formatAmount(fixedNumber(booking?.products?.[1]?.finalPrice?.number))
        : '',
      fee: booking?.products?.[1]?.finalPrice?.number
        ? formatAmount(fixedNumber(booking?.products?.[1]?.finalPrice?.number))
        : '-',
      initialFee: booking?.products?.[1]?.price?.number
        ? formatAmount(fixedNumber(booking?.products?.[1]?.price?.number))
        : '-',
      userInternalId: booking?.userInternalId || '-',
      userId: booking?.user_id,
      labels: [
        {
          text: contractType,
          styles: {
            backgroundColor: theme.palette.yellows.yellow200,
            color: theme.palette.yellows.yellow900,
          },
        },
        {
          text: booking?.origin,
          styles: {
            backgroundColor: theme.palette.blues.primaryBlue200,
            color: theme.palette.blues.primaryBlue800,
          },
        },
        {
          text: contractPeriod,
          styles: {
            backgroundColor: theme.palette.yellows.yellow200,
            color: theme.palette.yellows.yellow900,
          },
        },
      ],
    };
  });
};

export const handleAssigneeData = (data = []) =>
  data
    ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
    ?.map((item) => ({
      isActive: item?.isActive,
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }));

export const getBookingsPayload = (payload) => {
  const fields =
    omit(payload, [
      'page',
      'pageSize',
      'order',
      'orderBy',
      'searchTerm',
      'tenant',
    ]) || {};
  const { searchTerm, tenant } = payload;
  const numberFields = ['stage', 'contract_type'];

  const value = [
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: FILTER_RULE_ENUMS.ComparableRuleType,
      value: numberFields.includes(field)
        ? Number(payload[field])
        : payload[field],
    })),
  ];
  const tenants = Object.keys(TENANT_NAMES);

  // if tenant is instacar or instaride
  if (isNumber(tenant) && tenants.includes(tenant.toString())) {
    value.push({
      field: 'tenant',
      operator: FILTER_OPERATOR_ENUMS['='],
      type: FILTER_RULE_ENUMS.ComparableRuleType,
      value: Number(tenant),
    });
  } else {
    // all tenants
    const allTenantsRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: tenants.map((field) => ({
        field: 'tenant',
        type: FILTER_RULE_ENUMS.ComparableRuleType,
        operator: FILTER_OPERATOR_ENUMS['='],
        value: Number(field),
      })),
    };
    value.push(allTenantsRule);
  }

  if (searchTerm) {
    const filedsToSearch = [
      'id',
      'user_id',
      'user_internal_id',
      'first_name',
      'last_name',
      'email',
      'phone_number',
      'vehicle.title',
      'vehicle.id',
      'billingInfo.vat',
    ];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        field,
        operator: FILTER_OPERATOR_ENUMS.contains,
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  return {
    boardType: payload.boardType,
    type: FILTER_RULE_ENUMS.AndRuleType,
    field: null,
    operator: null,
    value,
  };
};
