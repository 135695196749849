import dynamic from 'next/dynamic';

const BookingsGrid = dynamic(() => import('./BookingsGrid/BookingsGrid'));

const BookingsHeaderOptions = dynamic(() =>
  import('./BookingsHeaderOptions/BookingsHeaderOptions')
);
const NewBookingModal = dynamic(() =>
  import('./NewBookingModal/NewBookingModal')
);
const NewBookingForm = dynamic(() => import('./NewBookingForm/NewBookingForm'));
const BookingProductsGrid = dynamic(() =>
  import('./BookingProductsGrid/BookingProductsGrid')
);
const BookingsFilters = dynamic(() =>
  import('./BookingsFilters/BookingsFilters')
);
const BookingsListContent = dynamic(() =>
  import('./BookingsListContent/BookingsListContent')
);

export {
  BookingsGrid,
  BookingsHeaderOptions,
  NewBookingModal,
  NewBookingForm,
  BookingProductsGrid,
  BookingsFilters,
  BookingsListContent,
};
