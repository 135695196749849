import { groupBy, isEmpty, omit } from 'lodash';

import { formatDate, sortArray } from '@utils';
import {
  ARNM_REASONS_REPAIRS_MAP,
  ARNM_REPAIRS_TASK_STATUSES_NAMES,
} from 'components/arnmRepairs/constants';
import {
  BOARDS_ID,
  FILTER_OPERATOR_ENUMS,
  FILTER_RULE_ENUMS,
} from '@constants';
import { URLS } from 'layouts/DrawerLayout/constants';

const getValue = (data, current) => {
  return data.find((item) => item?.value === current);
};

export const normaliseAssigneeData = (data = []) =>
  data
    .map((item) => ({
      isActive: item?.isActive,
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
      shortName:
        item.firstName && item.lastName
          ? `${item?.firstName[0].toUpperCase()}${item?.lastName[0].toUpperCase()}`
          : item.email[0].toUpperCase(),
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));

export const normalizeServicePointsTaskData = (servicePoints) => {
  if (!servicePoints) {
    return [];
  }
  return servicePoints?.map((item) => {
    const primaryContact = item?.contacts?.find(
      (contact) => contact?.isPrimary === true
    );
    return {
      text: item?.name,
      value: item?.id,
      email: primaryContact?.email,
    };
  });
};

export const handleArnmRepairTaskData = ({
  data,
  assignees = [],
  stages = [],
  servicePoints,
}) => {
  if (!data || isEmpty(data)) {
    return {};
  }
  const servicePoint = getValue(servicePoints, data.customFields.servicePoint);
  const assignee = getValue(assignees, data.assignee);
  const status = getValue(stages, data.status);

  const task = {
    ...data,
    assignee,
    status,
    changelog: data?.changelog?.reverse(),
    customFields: {
      ...omit(data.customFields, ['comments']),
      reason: ARNM_REASONS_REPAIRS_MAP[data.customFields?.reason] || '-',
      servicePoint,
      totalChargeClientAmount:
        data.customFields.totalChargeClientAmount?.number || null,
      totalOfferAmount: data.customFields.totalOfferAmount?.number || null,
      totalInvoiceCost: data.customFields.totalInvoiceCost?.number || null,
      totalCounterOfferAmount:
        data.customFields.totalCounterOfferAmount?.number || null,
      workOrder: data.customFields.workOrder?.map?.((item) => ({
        ...item,
        chargeClientAmount: item.chargeClientAmount?.number || null,
        offerAmount: item.offerAmount?.number || null,
        counterOfferSaving: item.counterOfferSaving?.number || null,
        counterOffers: item.counterOffers?.map((counterOffer) => {
          const counterOfferServicePoint = getValue(
            servicePoints,
            counterOffer.servicePoint
          );

          return {
            ...counterOffer,
            price: counterOffer.price.number || null,
            servicePoint: counterOfferServicePoint || {
              value: counterOffer.servicePoint,
              text: counterOffer.servicePoint,
            },
            hasServicePointWithId: Boolean(counterOfferServicePoint),
            servicePointText:
              counterOfferServicePoint?.text ||
              counterOffer.servicePoint ||
              '-',
          };
        }),
      })),
    },
    comments: sortArray(data?.comments, 'createdAt', 'desc')?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
    documents: data?.documents,
  };
  return task;
};

export const normalizeVehicleData = (data = {}) => {
  const properties = [];

  if (data.properties?.fuelType) {
    properties.push(data.properties.fuelType);
  }
  if (data.properties?.transmission) {
    properties.push(data.properties.transmission);
  }
  if (data.properties?.color) {
    properties.push(data.properties.color);
  }
  if (data.year) {
    properties.push(data.year);
  }

  return {
    internalId: data.internalId,
    id: data.id,
    title: `${data.manufacturer}, ${data.model}`,
    plate: data.plate,
    image: data?.images?.[0]?.url,
    properties,
    vin: data.vin,
    procurement: data.procurement,
  };
};

export const getPayload = (values) => {
  const clearableFields = ['dot', 'tires'];

  Object.keys(values)?.forEach((key) => {
    if (values[key] === '' && !clearableFields.includes(key)) {
      values[key] = null;
    }
  });

  const cleanedValues = omit(values, [
    'assignee',
    'documents',
    'tags',
    'status',
    'updatedAt',
    'updatedBy',
    'changelog',
    'servicePoint',
    'images',
    'parent',
    'name',
    'createdAt',
    'comments',
    'createdBy',
    'servicePoint',
    'subscriptionId',
    'customFields.vehicleDescription',
    'customFields.vehicleImage',
    'customFields.vehicleOwner',
    'customFields.vehiclePlate',
  ]);

  return {
    assignee: values.assignee?.value,
    customFields: {
      ...cleanedValues.customFields,
      servicePoint: values.customFields.servicePoint?.value,
      expectedOutFromServicePoint:
        values.customFields.expectedOutFromServicePoint || null,
      totalCounterOfferAmount: values.totalCounterOfferAmount || null,
      workOrder: cleanedValues.customFields.workOrder.map((workOrder) => ({
        ...workOrder,
        counterOffers: workOrder.counterOffers?.map((item) => ({
          servicePoint: item.servicePoint?.value || item.servicePoint,
          date: item.date,
          price: item.price,
          isSelected: Boolean(item.isSelected),
        })),
      })),
    },
  };
};

export const handleArnmRepairsData = (data = [], assignees = []) => {
  if (!data.length) {
    return [];
  }
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0] || ''}${
        cur.lastName?.[0] || ''
      }`.toUpperCase(),
    };
    return acc;
  }, {});

  const tasks = data.map((item) => {
    const assignee = assigneesMap[item.assignee] || {};
    return {
      ...item,
      href: `${URLS.arnmRepairs}/${item?.id}`,
      plate: item.customFields?.vehiclePlate || '',
      status: item?.status,
      assignee,
      description: item?.customFields?.vehicleDescription || '-',
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      // TODO: handle custom fields
      id: item?.id,
      servicePoint: item?.customFields?.servicePoint || '-',
      reason: ARNM_REASONS_REPAIRS_MAP[item?.customFields?.reason] || '-',
      outDateFromServicePoint: item?.customFields?.outDateFromServicePoint
        ? formatDate(item?.customFields?.outDateFromServicePoint, 'DD/MM/YYYY')
        : '-',
    };
  });

  return tasks;
};

export const getArnmRepairsColumns = (tasks = []) => {
  const order = [0, 2, 13, 14, 15, 3, 12];
  const groupedByStatus = groupBy(tasks, 'status');
  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: ARNM_REPAIRS_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: ARNM_REPAIRS_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: ARNM_REPAIRS_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const handleAssigneeData = (data = []) =>
  data
    .map((item) => ({
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));

export const getArnmRepairsPayload = (
  payload = {},
  modalFiltersRules = null
) => {
  const fields =
    omit(payload, ['page', 'pageSize', 'order', 'orderBy', 'boardType']) || {};
  const fieldsMap = {
    reason: 'customFields.reason',
    servicePoint: 'customFields.servicePoint',
    stage: 'status',
  };

  const multyFilterStringFields = ['reason', 'servicePoint'];
  const multyFilterNumberFields = [
    'stage',
    'reservationType',
    'procurementStageId',
  ];
  const defaultRule = {
    field: 'boardId',
    operator: FILTER_OPERATOR_ENUMS['='],
    type: FILTER_RULE_ENUMS.ComparableRuleType,
    value: BOARDS_ID.ARM_REPAIRS,
  };

  const getValueField = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }
    return payload[field];
  };
  const getType = ({ field }) => {
    if (
      multyFilterStringFields.includes(field) ||
      multyFilterNumberFields.includes(field)
    ) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };
  const rules = [...Object.keys(fields)].map((field) => ({
    field: fieldsMap[field] || field,
    operator: FILTER_OPERATOR_ENUMS['='],
    type: getType({ field }),
    value: getValueField({ field }),
  }));
  rules.push(...(modalFiltersRules?.value || []));
  rules.push(defaultRule);

  return {
    type: 0,
    field: null,
    operator: null,
    value: rules,
  };
};

export const handleServicePoints = (data = []) =>
  data
    ?.map((item) => {
      return {
        text: item?.name,
        value: item?.id,
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text));
