import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import { agentsServices, boardsServices } from '@services';
import { BOARDS } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { getUrl, showError } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';
import {
  getDefleetQueryParams,
  getDefleetRequestPayload,
  getColumns,
  normaliseAssigneeData,
  normaliseDefleetTasks,
} from './utils';
import { DEFLEET_PAGE_SIZE, DEFLEET_TABS } from './constants';

export const useDefleetOperations = () => {
  const router = useRouter();
  const page = router.query?.page || 1;
  const assignee = router.query?.assignee;
  const orderBy = router.query?.orderBy;
  const order = router.query?.order;
  const searchTerm = router.query?.searchTerm;
  const brand = router.query?.brand;
  const model = router.query?.model;
  const year = router.query?.year && Number(router.query?.year);
  const target = router.query?.target;
  const subscriptionStatus =
    router.query?.subscriptionStatus &&
    Number(router.query?.subscriptionStatus);
  const status = router.query?.status && Number(router.query?.status);
  const promisedReturnDate = router.query?.promisedReturnDate;
  const returnDate = router.query?.returnDate;
  const monthlyFee = router.query?.monthlyFee;
  const archived = router.query?.archived;

  const board = router.query?.board || Object.keys(DEFLEET_TABS)[0];

  if (!router.query?.brand && router.query?.model) {
    delete router.query.model;
    router.push(router);
  }

  const params = {
    page,
    assignee,
    orderBy,
    order,
    board,
    searchTerm,
    brand,
    model,
    year,
    target,
    subscriptionStatus,
    status,
    promisedReturnDate,
    returnDate,
    monthlyFee,
    archived,
  };

  const cleanParams = getDefleetQueryParams({
    ...params,
    boardType: BOARDS.DEFLEET,
  });
  const assigneesResponse = useQuery(QUERY_KEYS.GET_DEFLEET_ASSIGNEES_KEY, () =>
    agentsServices.getAgents({
      page: 1,
      pageSize: 3000,
    })
  );

  const defleetResponse = useQuery(
    [QUERY_KEYS.GET_DEFLEET_KEY, cleanParams],
    () =>
      boardsServices.getBillingBoardsFilters({
        boardId: DEFLEET_TABS[board].type,
        params: getDefleetQueryParams(cleanParams),
        payload: getDefleetRequestPayload({
          ...cleanParams,
          boardId: DEFLEET_TABS[board].id,
          boardType: DEFLEET_TABS[board].type,
        }),
      }),
    { enabled: assigneesResponse.isFetched }
  );

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }, URLS.defleet));
  };

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel }, URLS.defleet);

    router.push(url);
  };

  const defleetTasks = normaliseDefleetTasks(
    defleetResponse?.data?.data || [],
    assigneesResponse?.data?.data || []
  );

  const statuses = DEFLEET_TABS[board].statusOrder.reduce((acc, cur) => {
    acc.push({
      text: DEFLEET_TABS[board].statuses[cur].label,
      value: Number(cur),
    });
    return acc;
  }, []);

  const updateTask = async (payload = {}) => {
    try {
      if (!payload?.cardId) return;

      const taskId = payload?.cardId;
      delete payload?.cardId;
      await boardsServices.updateTask(taskId, payload);

      await boardsServices.updateTask(taskId, payload);
    } catch (error) {
      showError(error);
    }
  };

  return {
    page,
    pageSize: DEFLEET_PAGE_SIZE,
    assignees: normaliseAssigneeData(assigneesResponse?.data?.data || []) || [],
    total: defleetResponse?.data?.meta?.total,
    loading: defleetResponse?.isLoading || assigneesResponse?.isLoading,
    error: defleetResponse?.error,
    isSuccess: defleetResponse?.isSuccess,
    defleetTasks,
    board,
    statuses,
    columns: getColumns(defleetTasks || [], board),
    handlePageChange,
    handleSortBy,
    refresh: defleetResponse.refetch,
    updateTask,
  };
};
