import {
  ARNM_REASONS_MAP,
  BOARDS_ID,
  FILTER_OPERATOR_ENUMS,
  FILTER_RULE_ENUMS,
} from '@constants';
import { formatDate, sortArray } from '@utils';
import {
  ARNM_MAINTENANCE_TASK_STATUSES_NAMES,
  ARNM_REASONS_MAINTENANCE_MAP,
} from 'components/arnmMaintenance/constants';
import { URLS } from 'layouts/DrawerLayout/constants';
import { groupBy, omit } from 'lodash';

const filterServicePoint = (servicePoints, servicePointId) => {
  return (
    servicePoints.find((item) => item?.value === servicePointId) || {
      value: '',
      text: '',
    }
  );
};
const filterAssignee = (assignees, serviceAssignee) => {
  return (
    assignees.find((item) => item?.value === serviceAssignee) || {
      value: '',
      text: '',
    }
  );
};

export const handleArnmMaintenanceTaskData = (
  data,
  servicePoints = [],
  assignees = []
) => {
  if (!data) {
    return {};
  }
  const servicePoint = filterServicePoint(
    servicePoints,
    data.customFields.servicePoint
  );
  const assignee = filterAssignee(assignees, data.assignee);

  const task = {
    ...data,
    assignee,
    customFields: {
      ...omit(data.customFields, ['comments']),
      reason: ARNM_REASONS_MAINTENANCE_MAP[data.customFields?.reason] || '-',
      servicePoint,
      totalChargeClientAmount:
        data.customFields.totalChargeClientAmount?.number || null,
      totalOfferAmount: data.customFields.totalOfferAmount?.number || null,
      totalInvoiceCost: data.customFields.totalInvoiceCost?.number || null,
      workOrder: data.customFields.workOrder?.map?.((item) => ({
        ...item,
        chargeClientAmount: item.chargeClientAmount?.number || null,
        offerAmount: item.offerAmount?.number || null,
      })),
    },
    changelog: data?.changelog?.reverse(),
    comments: sortArray(data?.comments, 'createdAt', 'desc')?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.userFullName || entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
    documents: data?.documents,
  };
  return task;
};

export const handleServicePointsTaskData = (servicePoints) => {
  if (!servicePoints) {
    return [];
  }
  return servicePoints?.map((item) => {
    const primaryContact = item?.contacts?.find(
      (contact) => contact?.isPrimary === true
    );
    return {
      text: item?.name,
      value: item?.id,
      email: primaryContact?.email,
    };
  });
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${data?.internalId ? `${data?.internalId} -` : '' || ''} ${
      data?.vin ? `${data?.vin} -` : ''
    } ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${
      data?.properties?.transmission
        ? `${data?.properties?.transmission} -`
        : ''
    } ${data?.properties?.fuelType}`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
  };
  return vehicle;
};

export const handleAssigneeData = (data) => {
  if (!data) {
    return [];
  }

  return data
    ?.sort((a, b) => {
      return a.firstName.localeCompare(b.firstName);
    })
    ?.map((item) => ({
      isActive: item.isActive,
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }));
};

export const handleArnmMaintenanceData = (data = [], assignees = []) => {
  if (!data.length) {
    return [];
  }
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0] || ''}${
        cur.lastName?.[0] || ''
      }`.toUpperCase(),
    };
    return acc;
  }, {});

  const tasks = data.map((item) => {
    const assignee = assigneesMap[item.assignee] || {};
    return {
      ...item,
      href: `${URLS.arnmMaintenance}/${item?.id}`,
      status: item?.status,
      assignee,
      description: item?.name,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      // TODO: handle custom fields
      id: item?.id,
      colorName: item?.customFields?.doWeNeedATempCar && 'red',
      servicePoint: item?.customFields?.servicePoint || '-',
      reason: ARNM_REASONS_MAP[item?.customFields?.reason] || '-',
      outDateFromServicePoint: item?.customFields?.outDateFromServicePoint
        ? formatDate(item?.customFields?.outDateFromServicePoint, 'DD/MM/YYYY')
        : '-',
    };
  });

  return tasks;
};

export const getArnmMaintenanceColumns = (tasks = []) => {
  const order = [0, 2, 13, 14, 15, 3, 12];
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: ARNM_MAINTENANCE_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: ARNM_MAINTENANCE_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: ARNM_MAINTENANCE_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const getArnmMaintenancePayload = (
  payload = {},
  modalFiltersRules = null
) => {
  const fields =
    omit(payload, ['page', 'pageSize', 'order', 'orderBy', 'boardType']) || {};
  const fieldsMap = {
    reason: 'customFields.reason',
    servicePoint: 'customFields.servicePoint',
    stage: 'status',
  };

  const multyFilterStringFields = ['reason', 'servicePoint'];
  const multyFilterNumberFields = [
    'stage',
    'reservationType',
    'procurementStageId',
  ];
  const defaultRule = {
    field: 'boardId',
    operator: FILTER_OPERATOR_ENUMS['='],
    type: FILTER_RULE_ENUMS.ComparableRuleType,
    value: BOARDS_ID.ARM_MAINTENANCE,
  };

  const getValueField = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }
    return payload[field];
  };
  const getType = ({ field }) => {
    if (
      multyFilterStringFields.includes(field) ||
      multyFilterNumberFields.includes(field)
    ) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };
  const rules = [...Object.keys(fields)].map((field) => ({
    field: fieldsMap[field] || field,
    operator: FILTER_OPERATOR_ENUMS['='],
    type: getType({ field }),
    value: getValueField({ field }),
  }));
  rules.push(...(modalFiltersRules?.value || []));
  rules.push(defaultRule);

  return {
    type: 0,
    field: null,
    operator: null,
    value: rules,
  };
};

export const handleServicePoints = (data = []) =>
  data
    ?.map((item) => {
      return {
        text: item?.name,
        value: item?.id,
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text));
