import { formatDate, removeHtmlTags, timeFromNow } from '@utils';
import {
  _handleUserData,
  handleEmailListViewDate,
} from 'store/communication/communication.handlers';

export const getThreadPayload = ({ mailboxId, threadId }) => {
  return {
    type: 0,
    field: null,
    operator: null,
    value: [
      {
        field: 'mailboxId',
        operator: 0,
        type: 5,
        value: mailboxId,
      },
      {
        field: 'threadId',
        operator: 0,
        type: 5,
        value: threadId,
      },
    ],
  };
};

export const handleEmail = (data) => {
  if (!data) {
    return {};
  }
  const content =
    data?.body &&
    data?.body?.replace?.(
      'prefers-color-scheme: dark',
      'prefers-color-scheme: light'
    );

  return {
    ...data,
    body: content,
    updatedAt: data?.updatedAt
      ? `${formatDate(data?.updatedAt)} (${timeFromNow(data?.updatedAt)})`
      : '',
    createdAt: data?.createdAt
      ? `${formatDate(data?.createdAt)} (${timeFromNow(data?.createdAt)})`
      : '',
    // date: data?.messageDate
    //   ? `${formatDate(data?.messageDate)} (${timeFromNow(data?.messageDate)})`
    //   : '',
    date: data?.messageDate
      ? `${handleEmailListViewDate(data?.messageDate)} (${timeFromNow(
          data?.messageDate
        )})`
      : '',
    // to: _handleUserData(data?.to),
    cc: _handleUserData(data?.cc),
    bcc: _handleUserData(data?.bcc),
  };
};

export const handleHistory = (data) => {
  if (!data) {
    return [];
  }
  return data?.map((item) => {
    let to = item?.to !== null ? item?.to : '-';
    if (item?.field?.includes('Assignee') && Array.isArray(item?.to)) {
      const fullName =
        item?.to.first_name && item?.to.last_name
          ? `${item?.to.first_name} ${item?.to.last_name}`
          : '';
      to = fullName || item?.to.email || item?.to.username || to._id || '';
    }
    return {
      ...item,
      to,
    };
  });
};

export const handleThreadData = (data) => {
  if (!data || !data.length) {
    return [];
  }
  const thread = [...data];
  thread.splice(thread.length - 1, 1);

  if (!thread || !thread.length) {
    return [];
  }
  const emails = thread?.map((item) => ({
    ...item,
    createdAt: item?.createdAt ? formatDate(item?.messageDate, 'MMMM DD') : '',
    messageDate: item?.messageDate
      ? `${handleEmailListViewDate(item?.messageDate)} (${timeFromNow(
          item?.messageDate
        )})`
      : '',
    updatedAt: item?.updatedAt ? formatDate(item?.updatedAt, 'MMMM DD') : '',
    assignee: item?.assignee,
    snippet: removeHtmlTags(item?.snippet),
  }));
  return emails;
};

export const handleAssignees = (data = []) =>
  data.map((item) => ({
    ...item,
    value: item.id,
    text:
      item.firstName && item.lastName
        ? `${item.firstName} ${item?.lastName}`
        : item.email,
  }));
