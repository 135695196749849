import { exportFile, showError } from '@utils';
import moment from 'moment';
import { useState } from 'react';

export const useExportFile = (
  onExportRequest,
  fileName = 'downloaded file',
  fileType = 'text/csv'
) => {
  const [exporting, setExporting] = useState(false);

  const handleExportFile = async (params) => {
    try {
      setExporting(true);
      const file = await onExportRequest(params);
      if (!file) return;

      const formattedDate = moment().format('YYYYMMDD');
      const fileNameWithDate = `${fileName}_${formattedDate}`;

      exportFile(file, fileType, fileNameWithDate);
    } catch (error) {
      showError(error);
    } finally {
      setExporting(false);
    }
  };
  return {
    exporting,
    handleExportFile,
  };
};
