import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

// Initial state
const initialState = {
  filters: {
    modalFiltersRules: undefined,
    modalConditions: undefined,
  },
};
// Actual Slice
export const arnmRepairsSlice = createSlice({
  name: 'arnmRepairs',
  initialState,
  reducers: {
    filtersModalConditionArnmRepairsChangeSuccess: (state, { payload }) => {
      state.filters.modalConditions = payload;
      if (!payload?.rules?.length) {
        state.filters.modalFiltersRules = undefined;
      }
    },
    filtersModalRulesArnmRepairsChangeSuccess: (state, { payload }) => {
      state.filters.modalFiltersRules = payload;
    },
    resetModalArnmRepairsChangeSuccess: (state) => {
      state.filters.modalConditions = null;
      state.filters.modalFiltersRules = null;
    },
  },
  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.errors,
      };
    },
  },
});

export const {
  filtersModalConditionArnmRepairsChangeSuccess,
  filtersModalRulesArnmRepairsChangeSuccess,
  resetModalArnmRepairsChangeSuccess,
} = arnmRepairsSlice.actions;

export const selectArnmRepairsState = (state) => state.arnmRepairs;

export default arnmRepairsSlice.reducer;

export const filtersModalConditionArnmRepairsChange = (conditions) => {
  return (dispatch) => {
    dispatch(filtersModalConditionArnmRepairsChangeSuccess(conditions));
  };
};
export const filtersModalRulesArnmRepairsChange = (rules) => {
  // console.log(rules);
  return (dispatch) => {
    dispatch(filtersModalRulesArnmRepairsChangeSuccess(rules));
  };
};
export const resetModalArnmRepairsChange = () => {
  return (dispatch) => {
    dispatch(resetModalArnmRepairsChangeSuccess());
  };
};
