import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { bookingsServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';

import { handleBooking } from './bookings.handlers';

// Initial state
const initialState = {
  loading: false,
  errorMessage: '',
  booking: {},
  bookingSnapshot: null,
  getBookinghasErrors: false,
  getBookingErrorMessage: '',
};
// Actual Slice
export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    fetchBooking: (state) => {
      state.loading = true;
      state.getBookinghasErrors = false;
      state.getBookingErrorMessage = '';
    },
    fetchBookingSuccess: (state, { payload }) => {
      state.booking = handleBooking(payload?.data);
      state.loading = false;
    },
    fetchBookingFailure: (state, { payload }) => {
      state.loading = false;
      state.getBookinghasErrors = true;
      state.getBookingErrorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    addSnapshot: (state, { payload }) => {
      if (JSON.stringify(state.bookingSnapShot) === JSON.stringify(payload)) {
        return;
      }

      const user = JSON.parse(localStorage.user);
      const snapshot = {};
      snapshot[user.email] = payload;
      localStorage.bookingSnapshot = JSON.stringify(snapshot);
      state.bookingSnapshot = payload;
    },
    clearSnapshot: (state) => {
      const user =
        localStorage.user && localStorage.user !== 'undefined'
          ? JSON.parse(localStorage.user)
          : {};
      const snapshot =
        localStorage.bookingSnapshot &&
        localStorage.bookingSnapshot !== 'undefined'
          ? JSON.parse(localStorage.bookingSnapshot)
          : {};
      snapshot[user.email] = null;
      localStorage.bookingSnapshot = JSON.stringify(snapshot);
      state.bookingSnapshot = null;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchBooking,
  fetchBookingSuccess,
  fetchBookingFailure,
  addSnapshot,
  clearSnapshot,
} = bookingsSlice.actions;

export const selectBookingsState = (state) => state.bookings;

export default bookingsSlice.reducer;

export const getBooking = (bookingId) => {
  return async (dispatch) => {
    dispatch(fetchBooking());

    try {
      const response = await bookingsServices?.getBookingById(bookingId);

      dispatch(fetchBookingSuccess(response));
    } catch (error) {
      dispatch(fetchBookingFailure(error));
    }
  };
};
