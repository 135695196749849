import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const SUBSCRIPTIONS_LIMIT = 100;
export const SUBSCRIPTIONS_EXPORT_LIMIT = 20000;

// Initial state
const initialState = {
  filters: {
    urlParams: undefined,
    modalFiltersRules: undefined,
    modalConditions: undefined,
  },
};
// Actual Slice
export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    filtersModalConditionSubscriptionChangeSuccess: (state, { payload }) => {
      state.filters.modalConditions = payload;
      if (!payload?.rules?.length) {
        state.filters.modalFiltersRules = undefined;
      }
    },
    filtersModalRulesSubscriptionChangeSuccess: (state, { payload }) => {
      state.filters.modalFiltersRules = payload;
    },
    resetModalSubscriptionChangeSuccess: (state) => {
      state.filters.modalConditions = null;
      state.filters.modalFiltersRules = null;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  filtersModalConditionSubscriptionChangeSuccess,
  resetModalSubscriptionChangeSuccess,
  filtersModalRulesSubscriptionChangeSuccess,
} = subscriptionsSlice.actions;

export const selectSubscriptionsState = (state) => state.subscriptions;

export default subscriptionsSlice.reducer;

export const filtersModalConditionSubscriptionChange = (rules) => {
  return (dispatch) => {
    dispatch(filtersModalConditionSubscriptionChangeSuccess(rules));
  };
};
export const filtersModalRulesSubscriptionChange = (rules) => {
  return (dispatch) => {
    dispatch(filtersModalRulesSubscriptionChangeSuccess(rules));
  };
};
export const resetModalSubscriptionChange = () => {
  return (dispatch) => {
    dispatch(resetModalSubscriptionChangeSuccess());
  };
};
