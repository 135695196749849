import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import {
  selectSubscriptionsState,
  filtersModalRulesSubscriptionChange,
  filtersModalConditionSubscriptionChange,
  resetModalSubscriptionChange,
  SUBSCRIPTIONS_LIMIT,
} from '@store';
import { getCleanedUrlParams, getUrl, showError } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import {
  getSubscriptionsQueryParams,
  getSubscriptionsRequestPayload,
} from '@helpers';
import { subscriptionsServices } from '@services';
import { handleSubscriptionsData } from './utils';

export const useSubscriptionsOperations = () => {
  const { filters } = useSelector(selectSubscriptionsState);
  const dispatch = useDispatch();

  const { push, query, isReady } = useRouter();

  const {
    page = '1',
    orderBy = '',
    order = '',
    status,
    type,
    searchTerm,
  } = query;

  const cleanedParams = getCleanedUrlParams({
    ...query,
    page,
    orderBy,
    status,
    type,
    order,
    searchTerm,
  });

  const subscriptionsResponse = useQuery(
    [
      QUERY_KEYS.GET_SUBSCRIPTIONS_KEY,
      { cleanedParams, filters: filters.modalFiltersRules },
    ],
    () => {
      const payload = getSubscriptionsRequestPayload(
        cleanedParams,
        filters.modalFiltersRules
      );
      return subscriptionsServices.getSubscriptions({
        params: getSubscriptionsQueryParams(cleanedParams),
        payload,
      });
    },
    { enabled: isReady }
  );

  const filtersModalChange = useCallback(
    (conditions) => {
      dispatch(filtersModalConditionSubscriptionChange(conditions));
    },
    [dispatch]
  );

  const resetModalChange = useCallback(() => {
    dispatch(resetModalSubscriptionChange());
  }, [dispatch]);

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...query, ...sortByModel }, URLS.subscriptions);
    push(url);
  };

  const handlePageChange = (newPage) => {
    const currentPage = query.page || '1';
    if (newPage !== currentPage) {
      push(getUrl({ ...query, page: newPage }, URLS.subscriptions));
    }
  };

  const handleChangeRules = (rules) => {
    dispatch(filtersModalRulesSubscriptionChange(rules));
  };

  useEffect(() => {
    if (subscriptionsResponse?.error) {
      showError(subscriptionsResponse.error);
    }
  }, [subscriptionsResponse?.error]);

  return {
    page,
    pageSize: SUBSCRIPTIONS_LIMIT,
    total: subscriptionsResponse?.data?.meta?.total,
    loading: subscriptionsResponse?.isFetching,
    subscriptions: handleSubscriptionsData(
      subscriptionsResponse?.data?.data || []
    ),
    filters,
    getSubscriptions: subscriptionsResponse.refetch,
    nextPage: handlePageChange,
    sortBy: handleSortBy,
    filtersModalChange,
    resetModalChange,
    handleChangeRules,
  };
};
